import React from 'react'
import TickImg from "../../Assets/checkbox.svg"
import { useSelector } from 'react-redux'


const TickCard = ({data}) => {
    const { darkmode } = useSelector((state) => state.mode)

  return (
    <div className='flex items-center gap-2'>
        <img src={TickImg} alt="" className='h-20'/>
        <h1 className={`${darkmode ? " text-gray-200 ":" text-black"}  text-2xl`}>{data.name} </h1>
    </div>
  )
}

export default TickCard