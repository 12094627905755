import React, { useState } from 'react'
import { setDarkmode } from '../Slices/modeSlice';
import { useDispatch } from 'react-redux'
import logo from "../Assets/logo.svg"
import { IoMdMoon } from "react-icons/io";
import { MdWbSunny } from "react-icons/md";

const Navbar = () => {
    const [openDropDown, setDropDown] = useState(false)
    const [darkMode, setDarkmodes] = useState(false);
    const dispatch = useDispatch();
    dispatch(setDarkmode(darkMode))

    return (
        <div className=''>
            <nav className={`${darkMode ? "bg-gray-900 text-white" : "bg-white text-black"} border-gray-200  `}>
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="" className="flex overflow-hidden items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-[50px] w-[100px] scale-[1.5] overflow-hidden transform-gpu " alt="Flowbite Logo" />
                    </a>
                    <button onClick={() => setDropDown(!openDropDown)} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>


                    <div className={`${openDropDown ? "block" : "hidden"} w-full md:block md:w-auto`} id="navbar-default">
                        <ul className={`font-medium flex flex-col xm:gap-10 lg:items-center p-4 md:p-0 mt-4 border ${darkMode ? "bg-gray-900 md:bg-gray-900 border-gray-700" : "bg-gray-50 border-gray-100 md:bg-white"} rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0`}>
                            <li>
                                <a href="/" className={`block py-2 px-3 ${darkMode ? "text-white rounded md:text-blue-500 md:p-0" : "text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"} `} aria-current="page">Home</a>
                            </li>
                            <li>
                                <a href="/aboutUs" className={`block py-2 px-3 ${darkMode ? " text-white hover:bg-gray-700  hover:text-white  md:hover:bg-transparent" : " text-gray-900 "}hover:bg-gray-100  rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white`}>About</a>
                            </li>
                            <li>
                                <a href="#services" className={`block py-2 px-3 ${darkMode ? " text-white hover:bg-gray-700  hover:text-white  md:hover:bg-transparent" : " text-gray-900 "}hover:bg-gray-100  rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white`}>Services</a>
                            </li>
                            <li>
                                {/* <a href="#" className={`block py-2 px-3 ${darkMode ? " text-white hover:bg-gray-700  hover:text-white  md:hover:bg-transparent" : " text-gray-900 "}hover:bg-gray-100  rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white`}>About</a> */}
                                {/* <a href="#form" className='lg:bg-[#FFAF50] md:bg-[#FFAF50] h-[50px] bg-transparent lg:p-2 lg:px-5 px-3 rounded-full'>Contact Us</a> */}
                                <button href="#form" className='lg:bg-[#FFAF50] lg:text-black md:bg-[#FFAF50]  bg-transparent lg:p-2 lg:px-5 px-3 rounded-full'>
                                    <a href="#form">Contact Us</a>
                                    </button>

                            </li>
                          
                           <li>
                           <button className='sm:px-5 lg:mt-0 md:mt-0 mt-5' onClick={() => { setDarkmodes(!darkMode) }}>
                                {
                                    darkMode ? (<IoMdMoon size={25} />) : (<MdWbSunny size={25} />
                                   )
                                }
                            </button>
                           </li>
                        </ul>
                        
                    </div>
                </div>

            </nav>

        </div>
    )
}

export default Navbar