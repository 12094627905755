import React from 'react'
import ReactStars from 'react-stars'
import { useSelector } from 'react-redux'


const ReviewCard = ({ data }) => {
    const { darkmode } = useSelector((state) => state.mode)

    return (
        <div className={`${darkmode ? " border border-white":" border border-black"}  h-[270px] p-5 w-[270px] md:w-[370px] overflow-hidden flex flex-col `}>
            <h1 className={`overflow-hidden md:h-24 h-44  ${darkmode ? " text-gray-200 ":" text-black"} text-2xl mb-2` }>{data.name} {"~"} <br /> <span className={ `${darkmode ? " text-gray-400  ":" text-blue-gray-800"} text-xl`}> {data.designation} </span></h1>
            <p className={` overflow-hidden h-24 ${darkmode ? " text-white":" text-black"}`}>{data.comment} </p>
            <ReactStars
                count={5}
                edit={false}
                size={24}
                value={data.stars}
                color2={'#ffd700'}
                half={true} />
        </div>
    )
}

export default ReviewCard