import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    darkmode:null
}
const modeSlice = createSlice({
    name:"mode",
    initialState:initialStates,
    reducers:{

        setDarkmode(state ,value){
            state.darkmode=value.payload
            console.log("mode in modeSlice -->",state.darkmode);
        },
     
    }
})

export const {setDarkmode} = modeSlice.actions;
export default modeSlice.reducer;