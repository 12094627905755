import React from 'react'
import { useSelector } from 'react-redux'
import yellowCircle from "../Assets/yellowCircle.svg"
import TusharImg from "../Assets/Tushar.png"
import BlueImg from "../Assets/blueCircle.svg"
const AboutUs = () => {
    const { darkmode } = useSelector((state) => state.mode)

  return (
    <div className={` ${darkmode ? " bg-black":""} relative min-h-screen`}>
       <img src={yellowCircle} alt="" className=' animate-pulse absolute h-[100px] w-[100px] md:h-[200px] md:w-[200px] z-10'/>
       <div className=' flex flex-col md:flex-row md:p-7'>
            <div className='relative md:w-[60%]'>
                <h1 className={` relative z-20 text-3xl font-semibold mb-10 top-2 overflow-hidden  left-5 ${darkmode ? " text-gray-200":""}`}>More about us...</h1>
                <p className={`${darkmode ? " text-white":" text-black"} p-3 relative z-20 text-lg md:text-xl`}>
                TUAG, a growing Influencer and E-commerce Marketing agency, with an ROl driven vision and authentic story telling, aimed for creating brand advocacy for your company. Currently we are working with more than 50+ Brands and 2,00,000 content creators in India. We provide the best in class creator pricing, facilitates compliant creator contracts, ensures seamless on-time delivery and allow brands to track campaign performance in real time. We help Brands help to reliably drive campaigns at scale and speed.
                </p>
                <p className={`${darkmode ? " text-white":" text-black"} p-3 md:text-xl text-lg`}>
                Our Marquee clients include- Emcure Pharmaceuticals, Green Protein, Miniso, Tempt India, Halden Luxury, Cedo, Wacoal, Fizzy Goblet, Immunosciences amongst many others.
                </p>
            </div>
            <div className=' my-5 md:w-[40%]'>
                <img src={TusharImg} alt="not found" className=' object-cover md:h-[400px] md:w-[400px] md:mx-auto md:rounded-full' />
                <h1 className={`${darkmode ?" text-gray-300":" text-black" } text-center text-xl mx-auto font-semibold`}>Tushar Aggarwal ~ Founder </h1>
            </div>
            <img src={BlueImg} alt="" className='hidden md:block absolute bottom-0 right-0 animate-none h-[200px] w-[200px]  z-10'/>
       </div>
    </div>
  )
}

export default AboutUs