import React from 'react'
import TickCard from './TickCard'
const TickData=[
    {
        name:"Campaign Handling"
    },
    {
        name:"Personalised campaigns"
    },
    {
        name:"Privacy Maintainence"
    },
    {
        name:"Track campaigns"
    },
    {
        name:"Campaign suggestion"
    },
    {
        name:"Share your experience with us"
    }
]

const TickSection = () => {
  return (
    <div className=' grid grid-cols-1 md:grid-cols-2 '>
        {
            TickData.map((data)=>(
                 <TickCard data={data} />     
            ))
        }
    </div>
  )
}

export default TickSection