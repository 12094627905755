import React from 'react'

const ServiceCard = ({data}) => {
  return (
    <div className=' mx-auto p-8 bg-[#ECECEC] rounded-lg flex flex-col gap-5 items-center '>
        <img src={data.img} alt="" className=' w-[200px] h-[200px] object-contain' />     
        <h1 className=' relative '>{data.name}</h1>
    </div>
  )
}

export default ServiceCard