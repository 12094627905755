import React from 'react'
import { useSelector } from 'react-redux'
import { TypeAnimation } from 'react-type-animation';
import bannerImg from "../Assets/bannerImg.svg"

import infulencerIMG from "../Assets/InfluenceImg.svg"
import socialMediaImg from "../Assets/SocialMediaImg.svg"
import EcommerceImg from "../Assets/EcomImg.svg"
import ServiceCard from './Core/ServiceCard';
import metaImg from "../Assets/meta-icon.svg"
import TickSection from './Core/TickSection';
import ReviewSection from './Core/Reviews/ReviewSection';
import Carousel from './Core/Carousel/Carousel';
import Form from './Core/Form/Form';
import { PopupButton } from "react-calendly";

const LandingPage = () => {
    // CONNECT.ENGAGE.EARN
    const { darkmode } = useSelector((state) => state.mode)
    const serviceData = [
        {
            name: "Influencer Marketing",
            img: infulencerIMG
        },
        {
            name: "Social Media Marketing",
            img: socialMediaImg
        },
        {
            name: "E-Commerce Marketing",
            img: EcommerceImg
        },
        {
            name: "Meta Optimization",
            img: metaImg
        }
    ]
    return (
        <div>
            {/* section 1  */}
            <section className=' '>
                <div className={`flex flex-col p-2 sm:p-3 md:px-20 lg:p-20  md:flex-row ${darkmode ? "bg-gray-900" : "bg-white"}`}>
                    <div className='flex flex-col gap-4 md:w-[60%] lg:w-[50%] relative items-center md:items-start mt-10'>
                        <h1 className={` z-[1000] h-[35px] sm:h-[100px] text-2xl sm:text-3xl md:text-4xl lg:text-5xl ${darkmode ? " text-gray-100" : " text-[#003546]"} font-bold`}>
                            <TypeAnimation
                                sequence={[
                                    // Same substring at the start will only be typed out once, initially
                                    'CONNECT AND BUILD WITH US',
                                    2000, // wait 1s before replacing "Mice" with "Hamsters"
                                    ""
                                ]}
                                wrapper="span"
                                speed={50}
                                cursor={false}
                                omitDeletionAnimation={true}

                                //   style={{ fontSize: '2em', display: 'inline-block' }}
                                repeat={Infinity}
                            />
                        </h1>
                        <p className={`text-sm sm:text-lg  ${darkmode ? " text-gray-400" : " text-black"}`}>Get more customers with Marketing. Grow your business with us. It’s easy!</p>
                        <div className='flex gap-6 '>
                            <button className={`bg-[#FFAF50] p-2 px-5 rounded-full text-black`}>
                                
                                <PopupButton
                                    url="https://calendly.com/tusharaggarwal301"
                                    rootElement={document.getElementById("root")}
                                    text="Book a call"
                                    styles={{  border: "none", fontSize: "large", cursor: "pointer" }}
                                    id="calendly"
                                />
                            </button>
                            <button className={` ${darkmode ? "bg-gray-300 text-black" : "bg-[#003C4F] text-white"} lg:p-2 px-5 rounded-full`}>
                                <a href="/aboutUs">About Us</a>
                            </button>
                        </div>
                    </div>
                    <div className='hidden md:block  md:w-[40%] '>
                        <img src={bannerImg} alt="" className='w-[550px]' />
                    </div>
                </div>

            </section>
            {/* Section 2 */}
            <section className=' section2 p-2 sm:p-3 lg:px-20 lg:p-20 flex flex-col scroll-smooth' id='services'>
                <div>
                    <p className=' text-[#E97C00]'>What Can We Do For You</p>
                    <h1 className={`${darkmode ? " text-gray-100 " : " text-black"} text-3xl`}>Services We Can Help You With</h1>
                </div>
                <div className=''>
                    <div className='relative z-10 mt-10  flex flex-col md:flex-row gap-4 mx-auto'>
                        {
                            serviceData.map((data) => {
                                return <ServiceCard data={data} />
                            })
                        }
                    </div>
                  

                </div>
            </section>
            {/* section 3 */}
            <section className=' section2 p-20 mx-auto flex flex-col'>
                <h1 className={` h-[100px] ${darkmode ? " text-gray-300" : " text-black"} text-4xl mx-auto semibold my-5`}>Why Choose Us?</h1>
                <TickSection />
            </section>
            {/* Section 4 */}
            <section className=' mx-auto section2 p-2 sm:p-3 lg:px-20 lg:p-20 flex flex-col'>
                <h1 className={` h-[100px] ${darkmode ? " text-gray-300" : " text-black"} text-4xl mx-auto semibold my-5`}>Client Testimonials</h1>
                <ReviewSection />
            </section>
            <section className='mx-auto  h-[300px] bg-[#003C4F]'>
                <h1 className={` overflow-hidden mt-5 ${darkmode ? " text-white" : " text-white"} text-4xl text-center`}>Trusted By The Best companies</h1>
                <Carousel />
            </section>
            <section className=' mx-auto section2 p-2 scroll-smooth' id='form'>
                <Form />
            </section>
        </div>

    )
}

export default LandingPage