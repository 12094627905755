import { Routes,Route } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import LandingPage from './Components/LandingPage';
import Navbar  from './Components/Navbar';
import { useSelector } from 'react-redux'
import AboutUs from './Components/AboutUs';
import Error from './Components/Error';
function App() {

  const {darkmode}=useSelector((state)=>state.mode)

  return (
    <div className={`${darkmode ? " bg-gray-900":" bg-white"}`}>
      <Navbar/>
      <Routes>
      <Route index element={<LandingPage/>}/>
      <Route  path="/" Component={<LandingPage/>}/>
      <Route  path="aboutUs" element={<AboutUs/>}/>
      <Route  path="*" element={<Error/>}/>
      </Routes>
      <Footer/>
    </div>

  );
}

export default App;
