import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';
import { useSelector } from 'react-redux'

const Form = () => {
    const { darkmode } = useSelector((state) => state.mode)
    const notify = () => toast.success("Thank You for contacting us");
    const errorToast = () => toast.error("Sorry! Message not sent ")
    const form = useRef();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [message, setMessage] = useState("")
   
    const sendEmail = (e) => {
        e.preventDefault();
        if (!name || !email || !number || !message) {   
            toast.error("Please enter all the field properly")
        } else {
            emailjs.sendForm('service_ju4xvo1', 'template_hemtt1p', form.current, 'mMqBT9sWX7DM9CKA2')
                .then((result) => {
                    console.log(result.text);
                    notify()
                    setName("");
                    setEmail("");
                    setMessage("");
                    setNumber("");
                }, (error) => {
                    console.log(error.text);
                    errorToast()
                    setName("");
                    setEmail("");
                    setMessage("");
                    setNumber("");
                });
        }
    };


    return (
        <div className=' flex gap-2 p-2  md:p-12 justify-between  mx-auto w-full '>
            <div className=' hidden md:block'>
                <h1 className={`${darkmode ? " text-gray-300" : " text-black"} text-5xl h-[95px] overflow-hidden`}>Connect With Us !</h1>
                <p className={`${darkmode ? " text-gray-400" : "text-black"} text-3xl`}>We are here to help you.</p>

            </div>
            <div className=' bg-[#FFBC6C] w-[500px] mx-auto md:mx-0 rounded-3xl p-5'>
                <h2 className=' text-3xl m-4 text-center'>Send a query</h2>
                <form ref={form} className=' relative w-full mx-auto' >
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`${darkmode ? " bg-gray-300 text-black" : " bg-black text-gray-400"} w-full h-10 p-2 rounded-xl`} placeholder='Name' name="from_name" required /> <br /> <br />
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="" placeholder='Your email ' required className={`${darkmode ? " bg-gray-300 text-black " : " bg-black text-gray-400"} w-full h-10 p-2 rounded-xl`} /> <br /> <br />
                    <input type="text" value={number} onChange={(e) => setNumber(e.target.value)} name='mobile' placeholder='Contact No' required className={`${darkmode ? " bg-gray-300 text-black" : " bg-black text-gray-400"} w-full h-10 p-2 rounded-xl`} /> <br /> <br />
                    <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} id="" placeholder='How can we help you ?' cols="30" rows="10" required className={`${darkmode ? " bg-gray-300 text-black" : " bg-black text-gray-400"} w-full  p-2 rounded-xl`}></textarea> <br /> <br />
                    <button className=' bg-gray-300 p-3 rounded-2xl absolute bottom-4 left-[70px] md:left-[150px] hover:cursor-pointer hover:bg-[#003C4F] hover:text-white transition-all delay-75' onClick={sendEmail}>Submit Query</button>
                </form>
            </div>
        </div>
    )
}

export default Form;