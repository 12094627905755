import React from 'react'
import { useSelector } from 'react-redux'

const Footer = () => {
    const { darkmode } = useSelector((state) => state.mode)

  return (
    <div className={`${darkmode ? " ":""} bg-[#003C4F] flex flex-col md:flex-row gap-10 md:gap-2 justify-between p-8`}>
        <div className='footer-title'>
        <h1 className=' text-4xl h-[50px] font-semibold text-gray-100'>TUAG</h1>
        <h2 className=' text-2xl text-gray-200'>2024 &copy; Tushar Aggarwal Digital Private Limited</h2>
        <p className=' text-lg text-gray-200'>All Rights Reserved</p>
        </div>
        <div className=' flex flex-col mr-0 md:mr-14'>
        <a href="https://www.instagram.com/tuag_marketing?igsh=ZGNjOWZkYTE3MQ==" target='_blank' className=' text-white hover:cursor-pointer hover:text-[#FFBC6C]'>Instagram</a>
        <a href="https://www.linkedin.com/company/tuag/" target='_blank'  className=' text-white hover:cursor-pointer hover:text-[#FFBC6C]'>Linkedin</a>
        </div>
       
    </div>
  )
}

export default Footer