import React from 'react'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ReviewCard from './ReviewCard';

const ReviewData=[
    {
        name:"Shivansh Kaushik",
        designation:"Founder- Primastat",
        comment:"The best part about TUAG is the Founder is always available 24×7 for any queries",
        stars:4.5
    },
    {
        name:"J.K.Aggarwal",
        designation:"Director- Premannhar",
        comment:"Management and execution of the campaigns are always on time",
        stars:5
    },
    {
        name:"Shreya Khanna",
        designation:"Founder- Sarya Closet",
        comment:"Perfect Agency for Influencers as the payments are always on time and work is not delayed.",
        stars:4.5
    }
]
const ReviewSection = () => {
    return (
        <div className='w-11/12 mx-auto flex flex-col md:flex-row items-center gap-5 md:gap-5'>
                {
                    ReviewData.map((data)=>(
                        <ReviewCard data={data}/>
                    ))
                }
        </div>

    )
}

export default ReviewSection