import React from 'react'
import green from  '../../../Assets/Brands/green-protein.jpeg'
import halden from  '../../../Assets/Brands/halden.png'
import kuberlo from  '../../../Assets/Brands/kuberlo.png'
import medtech from  '../../../Assets/Brands/medtech.jpeg'
import savvy from  '../../../Assets/Brands/savvy.jpeg'
import allter from '../../../Assets/Brands/allter.jpeg'
import immuno from '../../../Assets/Brands/immuno.jpeg'
import inaldo from '../../../Assets/Brands/indalo.jpeg'
import primastat from '../../../Assets/Brands/primastat.jpeg'
import sahaj from '../../../Assets/Brands/sahaj.jpeg'

import Slider from "react-slick";
import tempt from '../../../Assets/Brands/tempt.jpeg'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux'



const Carousel = () => {
    const { darkmode } = useSelector((state) => state.mode)
    const settings = {
        
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
    const data = [
        {
            name:'Green Protein',
            img: green
        },
        {
            name:'Haldèn',
            // img:'../Assets/Brands/halden.png'
            img: halden
        },
        {
            name:'Kuberlo',
            img:kuberlo
        },
        {
            name:'Medtech',
            img:medtech
        },
        {
            name:'Savvy & Groovy',
            img:savvy
        },
        {
            name:'.Allter',
            img:allter
        },
        {
            name:'ImmunoSciences',
            img:immuno
        },
        {
            name:'Inaldo',
            img:inaldo
        },
        {
            name:'Primastat',
            img:primastat
        },
        {
            name:'Sahaj Naturals',
            img:sahaj
        },
        {
            name:'Tempt',
            img:tempt
        }
    ]
  return (
    
    <div className= ' mx-auto mt-10  '>
        <Slider {...settings} className=''> 
            {
                data.map((d)=>{                  
                  return (
                     <div className=' mx-auto flex flex-col items-center '>
                        <div className=' w-[100%] '>
                        <img src={d.img} alt="" style={{width:'170px'}} className='  items-center' />
                        </div>
                       
                        </div>
                  )
                })
            }
          </Slider>
    </div>
  )
}

export default Carousel