import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from "@reduxjs/toolkit";
import { BrowserRouter } from "react-router-dom";
import rootReducer from "./Reducers/index";
import { Provider } from "react-redux";
import  { Toaster } from 'react-hot-toast';

const store = configureStore({
  reducer:rootReducer
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
    <Provider store={store}>
    <App />
    <Toaster />
    </Provider>
    </BrowserRouter>

);


reportWebVitals();
